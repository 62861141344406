import React from 'react';
import { Grid, Box } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityDependencyOfComponentsCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CHILD_OF,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_MEMBER,
  RELATION_HAS_PART,
  RELATION_MEMBER_OF,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PARENT_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';

import { EntityLinguistCard } from '@backstage-community/plugin-linguist';
import { EntityTodoContent } from '@backstage-community/plugin-todo';
import { EntityTeamPullRequestsContent } from '@backstage-community/plugin-github-pull-requests-board';
import {
  EntityGithubInsightsReleasesCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityGithubDeploymentsCard,
  isGithubDeploymentsAvailable,
} from '@backstage-community/plugin-github-deployments';
import {
  EntityGithubPullRequestsContent,
  EntityGithubPullRequestsOverviewCard,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import { InfoCard } from '@backstage/core-components';
import {
  EntityFeedbackResponseContent,
  EntityStarredRatingsCard,
  StarredRatingButtons,
} from '@backstage-community/plugin-entity-feedback';
import { CustomNode as renderNode } from './CustomNode';

import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import {
  EntityPagerDutyCard,
  isPluginApplicableToEntity as isPagerDutyAvailable,
} from '@pagerduty/backstage-plugin';
import { ComplianceChecklistContent } from '@hingehealth/plugin-compliance-checklist';
import { EntityKubernetesContent } from '@backstage/plugin-kubernetes';
import { EntityAboutCard } from '@hinge-health/plugin-entity-overview';
import {
  EntityBazaarInfoCard,
  isBazaarAvailable,
} from '@backstage-community/plugin-bazaar';
import { EntitySentryContent } from '@backstage-community/plugin-sentry';

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const todoContent = (
  <Grid>
    <EntityTodoContent />
  </Grid>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <Grid container direction="column">
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubActionsAvailable}>
        <Box sx={{ fontSize: '28px', fontWeight: 'bold', m: 1 }}>
          GitHub Actions
        </Box>
        <EntityGithubActionsContent />
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubDeploymentsAvailable}>
        <EntityGithubDeploymentsCard />
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubInsightsAvailable}>
        <Grid item xs={12}>
          <EntityGithubInsightsReleasesCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
        <Grid item xs={12}>
          <EntityGithubPullRequestsContent />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const feedbackContent = (
  <Grid item md={6}>
    <InfoCard title="Rate this entity">
      <StarredRatingButtons />
    </InfoCard>
  </Grid>
);

const complianceContent = (
  <Grid>
    <ComplianceChecklistContent />
  </Grid>
);

const entityCatalogGraph = (
  <EntityCatalogGraphCard
    variant="gridItem"
    direction={Direction.LEFT_RIGHT}
    title="Architectural Context"
    maxDepth={1}
    height={600}
    relations={[
      RELATION_API_CONSUMED_BY,
      RELATION_API_PROVIDED_BY,
      RELATION_CONSUMES_API,
      RELATION_PROVIDES_API,
      RELATION_DEPENDENCY_OF,
      RELATION_DEPENDS_ON,
      RELATION_HAS_PART,
      RELATION_PART_OF,
      RELATION_OWNED_BY,
      RELATION_OWNER_OF,
      RELATION_PARENT_OF,
      RELATION_CHILD_OF,
      RELATION_HAS_MEMBER,
      RELATION_MEMBER_OF,
    ]}
    renderNode={renderNode}
    unidirectional={false}
  />
);

const overviewContent = (
  <Grid container spacing={3} alignItems="flex-start">
    {entityWarningContent}
    <Grid container direction="column" item md={6}>
      <EntityAboutCard variant="gridItem" />
      <EntityLinksCard variant="gridItem" />
      <EntitySwitch>
        <EntitySwitch.Case if={isBazaarAvailable}>
          <EntityBazaarInfoCard />
        </EntitySwitch.Case>
      </EntitySwitch>
      <EntityLinguistCard />
      <EntitySonarQubeCard variant="gridItem" />
      {feedbackContent}
    </Grid>
    <Grid container direction="column" item md={6}>
      <EntitySwitch>
        <EntitySwitch.Case if={isPagerDutyAvailable}>
          <EntityPagerDutyCard />
        </EntitySwitch.Case>
      </EntitySwitch>
      {entityCatalogGraph}
      <EntityGithubPullRequestsOverviewCard variant="gridItem" />
    </Grid>
  </Grid>
);

const libraryEntityPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/todo" title="TODOs">
      {todoContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const serviceEntityPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependencyOfComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/todo" title="TODOs">
      {todoContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/compliance" title="Compliance">
      {complianceContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependencyOfComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/todo" title="TODOs">
      {todoContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/compliance" title="Compliance">
      {complianceContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const defaultEntityPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('library')}>
      {libraryEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('app')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const resourcePage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependencyOfComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/sentry" title="Sentry">
      <EntitySentryContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/todo" title="TODOs">
      {todoContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/compliance" title="Compliance">
      {complianceContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const apiPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          {entityCatalogGraph}
        </Grid>
        {feedbackContent}
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item md={12}>
          <EntityCatalogGraphCard
            variant="gridItem"
            direction={Direction.LEFT_RIGHT}
            title="User Context"
            height={600}
            maxDepth={1}
            relations={[RELATION_OWNER_OF, RELATION_MEMBER_OF]}
            unidirectional={false}
            renderNode={renderNode}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
        <Grid item md={12}>
          <EntityCatalogGraphCard
            variant="gridItem"
            direction={Direction.LEFT_RIGHT}
            title="Group Context"
            height={600}
            maxDepth={1}
            relations={[
              RELATION_OWNER_OF,
              RELATION_PARENT_OF,
              RELATION_CHILD_OF,
            ]}
            unidirectional={false}
            renderNode={renderNode}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityTeamPullRequestsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/feedback" title="Feedback summary">
      <EntityStarredRatingsCard />
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        renderNode={renderNode}
        unidirectional={false}
      />
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout
    UNSTABLE_contextMenuOptions={{
      disableUnregister: true,
    }}
  >
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          {entityCatalogGraph}
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/feedback" title="Feedback">
      <EntityFeedbackResponseContent />
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('resource')} children={resourcePage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
